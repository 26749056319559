<template>
  <div class="main-bg-color h-100vh">
    <div class="logo-image-container text-center login-header mx-3">
      <!-- <img src="@/assets/images/kerry-logo.jpg" alt="Kitten" class="logo-img" /> -->
      <img src="@/assets/images/KEX_logo_RGB-White.png" alt="Kitten" class="logo-img" />
    </div>
    <div class="d-flex justify-content-center w-100 login-container">
      <div class="w-100 my-auto">
        <div class="d-flex justify-content-center">
          <div
            v-if="this.userData.isInitPrechat && this.userData.adminMode"
            class="card p-3 session-card session-h"
          >
            <div class="text-center f-size-18 px-3 py-5">
              {{ $t("msgQueueBusy2") }}
            </div>
          </div>

          <div v-else class="card p-3 mx-3 session-card session-h">
            <div v-if="queueDetail.queueNumber > 50" class="pt-4 px-2">
              <div class="text-center f-size-18">
                {{ $t("msgQueueBusy1") }}
              </div>
              <div class="text-center f-size-18">
                {{ $t("msgQueueBusy2") }}
              </div>
              <div class="text-center f-size-18">
                {{ $t("msgQueue3") }}
              </div>
              <div class="text-center f-size-18">
                {{ $t("msgQueue4") }}
              </div>
              <div class="text-center f-size-18">
                {{ $t("msgQueue5") }}
              </div>
            </div>
            <div
              v-if="
                queueDetail.queueNumber < 50 && queueDetail.queueNumber != 0
              "
              class="py-t px-2"
            >
              <div class="text-center f-size-18">
                {{ $t("msgQueue1") }}
              </div>
              <div class="text-center text-queue-count">
                {{ queueDetail.queueNumber }}
              </div>
              <div class="text-center f-size-18">
                {{ $t("msgQueue2") }}
              </div>
              <div class="text-center f-size-18">
                {{ $t("msgQueue3") }}
              </div>
              <div class="text-center f-size-18">
                {{ $t("msgQueue4") }}
              </div>
              <div class="text-center f-size-18">
                {{ $t("msgQueue5") }}
              </div>
            </div>
            <div v-if="queueDetail.queueNumber == 0" class="py-4 px-2">
              <div class="text-center f-size-18 py-3">
                {{ $t("theStaffHasAlreadyServed") }}
              </div>
              <br />
              <div class="text-center f-size-18">
                {{ $t("forMoreInformation") }}
              </div>
              <div class="text-center f-size-18">
                {{ $t("youCanTypeContactAdmin") }}
              </div>
            </div>
            <br />
            <div class="d-flex justify-content-center flex-column px-3 pt-3">
              <template v-if="queueDetail.queueNumber > 0">
                <div class="text-center">
                  <b-button
                    size="lg"
                    class="btn-main px-4 w-100"
                    @click="handleClickContact"
                    >{{ $t("notifyCallBack") }}
                  </b-button>
                </div>
                <div class="text-center mt-2">
                  <b-button
                    size="lg"
                    class="btn-main px-4 w-100"
                    @click="btnCloseWindow"
                    >{{ $t("waitStaff") }}
                  </b-button>
                </div>
              </template>
              <template v-else>
                <div class="text-center mt-2">
                  <b-button
                    size="lg"
                    class="btn-main px-4 w-100"
                    @click="btnCloseWindow"
                    >{{ $t("closeWindow") }}
                  </b-button>
                </div>
              </template>
            </div>
            <div
              v-if="queueDetail.queueNumber > 0 && updateTime"
              class="text-center f-size-18 pt-2"
            >
              {{ $t("updatedOn") }} {{ updateTime | moment("LL") }}
              {{ updateTime | moment("HH:mm:ss") }}
              {{
                $cookies.isKey("language") && $cookies.get("language") == "th"
                  ? "น."
                  : ""
              }}
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment/moment";
export default {
  components: {},
  data() {
    return {
      lineProfile: {
        userId: "",
        displayName: "",
        pictureUrl: "",
        statusMessage: ""
      },
      userData: {
        userGUID: "",
        channelID: "",
        channelReferenceId: ""
      },
      queueDetail: {
        queueNumber: 0,
        queueBefore: 0,
        createdTime: ""
      },
      updateTime: "",
      waitTimeMin: ""
    };
  },
  beforeCreate: async function () {
    if (this.$liff.isInClient()) {
      const isKerryFriend = this.$route.query.isKerryFriend || false;
      this.$liff
        .init({
          liffId: isKerryFriend
            ? this.$liff_Main_ID_Kerry_Friend
            : this.$liff_Main_ID
        })
        .then(() => {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
          });
        })
        .catch(error => {
          console.error("error", error);
        });
    }
  },
  created: async function () {
    this.getUserData();
  },
  methods: {
    getUserData: async function () {
      await this.$axios
        .get(this.$baseUrl + "/user/" + this.$route.query.sessionId)
        .then(response => {
          if (response.data.result === 1) {
            if (response.data.detail.userGUID) {
              this.userData = response.data.detail;
              if (this.userData.isInitPrechat && this.userData.adminMode) {
                this.btnCloseWindow();
              } else {
                this.getQueue();
              }
            } else {
              this.$router.push("/session-expired");
            }
          }
        });
    },
    getQueue: async function () {
      let lang = this.$cookies.isKey("language")
        ? this.$cookies.get("language")
        : "en";
      this.updateTime = moment().locale(lang);
      await this.$axios
        .post(this.$baseUrl + "/user/generatequeue", {
          userGUID: this.userData.userGUID,
          channelID: this.userData.channelReferenceId
        })
        .then(response => {
          if (response.data.result === 1) {
            this.queueDetail = response.data.detail;
            this.updateTime = moment(response.data.detail.createdTime).locale(
              lang
            );
            this.waitTimeMin = response.data.detail.waitTime;
          } else {
            this.$router.push("/session-expired");
          }
        })
        .catch(err => {
          alert(err.message);
        });
    },
    handleClickContact() {
      const isKerryFriend = this.$route.query.isKerryFriend || false;
      if (isKerryFriend) {
        this.$router.push({
          path: `/complain/kerryfriend/th?sessionId=${
            this.$route.query.sessionId || ""
          }&sentimentScore=&adminReply=false&isKerryFriend=true`
        });
      } else {
        this.$router.push({
          path: `/complain/th?sessionId=${
            this.$route.query.sessionId || ""
          }&sentimentScore=&adminReply=false`
        });
      }
    },
    btnCloseWindow() {
      window.close();
      this.$liff.closeWindow();
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-play {
  position: absolute;
  top: 30%;
  left: 27%;
  z-index: 99;
  color: white;
  width: 40px;
  height: 40px;
}

.logo-img {
  width: 100px;
  height: auto;
  text-align: center;
}

.text-queue-count {
  font-size: 80px;
}

.session-h {
  min-height: 65vh;
}

@media only screen and (min-width: 767.98px) {
}
</style>
